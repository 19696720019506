import { memo } from 'react';
import { Link as HeroUiLink } from '@heroui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { useLinkParams } from 'NavigationHelpers';
import { Button } from 'Button';
import { type ClickableTextProps, type IconName } from './ClickableText.types';

function AnchorIcon({ iconName }: { iconName: IconName }) {
    switch (iconName) {
        case 'right-arrow':
            return <FontAwesomeIcon className="ms-1 rtl:rotate-180" icon={faArrowRight} />;
        case 'chevron-down':
            return <FontAwesomeIcon className="ltr:ml-1 rtl:mr-1" icon={faChevronDown} />;
        case 'chevron-up':
            return <FontAwesomeIcon className="ltr:ml-1 rtl:mr-1" icon={faChevronUp} />;
        default:
            return null;
    }
}

function ClickableTextComponent({
    color = 'primary',
    weight = 'normal',
    anchorIcon = 'none',
    underline = 'always',
    target,
    href,
    allowWrap = false,
    children,
    className,
    onPress,
}: ClickableTextProps) {
    // When we don't have an href, we render as a Button to provide better semantics for accessibility
    // (e.g. screen readers) since an `<a>` tag without an `href` attr is not considered valid HTML.
    const as = href ? HeroUiLink : Button;

    const classes = clsx(
        color === 'slate-grey' && 'text-slate-grey',
        allowWrap ? 'inline' : '',
        {
            'font-normal': weight === 'normal',
            'font-medium': weight === 'medium',
            'font-semibold': weight === 'semibold',
        },
        // When rendering as a Button, we need to apply some additional CSS classes to match
        // the styling when rendering as a HeroUiLink.
        as === Button ? 'min-w-0 h-auto gap-0 bg-transparent rounded-none antialiased px-0' : '',
    );

    ({ href, target } = useLinkParams({ href, target }));

    const props = {
        as,
        className: twMerge(classes, 'text-base decoration-[0.8px] underline-offset-[3px]', className),
        underline,
        showAnchorIcon: anchorIcon !== 'none',
        anchorIcon: <AnchorIcon iconName={anchorIcon} />,
        href: href || undefined,
        target: target || undefined,
        onPress,
    };

    // FIXME: special offlineMode handling like loadUrl has. https://trello.com/c/rpUhP05h

    // HeroUiLink will handle the link according to the rules defined in the navigate and useHref properties
    // passed to HeroUIProvider in the FrontRoyalRouter file
    return (
        // When rendered as a Button, HeroUi will apply the `data-hover` attribute to the button element.
        // This results in HeroUi's native Button `data-[hover]` CSS styles being applied, which prevents
        // the opacity from being changed to match the opacity when rendered as a HeroUiLink. To prevent
        // HeroUi's native Button `data-[hover]` CSS class styles from being applied, we explicitly set
        // `data-hover` to false.
        <HeroUiLink data-hover={false} {...props}>
            {children}
        </HeroUiLink>
    );
}

export const ClickableText = memo(ClickableTextComponent) as typeof ClickableTextComponent;
